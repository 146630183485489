import React from "react";
import { Route, Switch } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {isMobile} from 'react-device-detect'



import { NavBar, Footer, Loading } from "./components";
import { Home, Profile, ExternalApi } from "./views";
import ProtectedRoute from "./auth/protected-route";

import "./app.css"
const App = () => {
  const { isLoading } = useAuth0();
  // const isMobile = useCheckMobileScreen()
  if (isLoading) {
    return <Loading />;
  }

  return (
  
    <div >
       {isMobile ? <NavBar /> : undefined}
      
      <div >

        <Switch>
          <Route path="/" exact component={Home} />
      
        </Switch>
  
      </div>
  
    </div>
  
  );
};

export default App;
