import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth/auth0-provider-with-history";

import firebase from 'firebase'
import { ChakraProvider } from "@chakra-ui/react"

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';


import "./index.css";



firebase.initializeApp({
  apiKey: "AIzaSyDwZzMgjitxI2dh1E6NPg_r3VCqiERpWUk",
  authDomain: "the-legacy-b3f2f.firebaseapp.com",
  databaseURL: "https://the-legacy-b3f2f-default-rtdb.firebaseio.com",
  projectId: "the-legacy-b3f2f",
  storageBucket: "the-legacy-b3f2f.appspot.com",
  messagingSenderId: "180313932403",
  appId: "1:180313932403:web:22f06b35fb5b70d29b6006"
})

ReactDOM.render(

  <Router>
    
    <Auth0ProviderWithHistory>
      <ChakraProvider>
           <App />
      </ChakraProvider>
    </Auth0ProviderWithHistory>
 
  </Router>,
  document.getElementById("root")
);



serviceWorkerRegistration.register()

reportWebVitals();