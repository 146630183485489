import firebase from 'firebase'
import React, { useEffect, useRef } from  'react'
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from 'js-cookie'
import { v4 as uuidv4 } from 'uuid';
import {isMobile} from 'react-device-detect'

import {Box,Center,Image,Heading, useMediaQuery} from "@chakra-ui/react"

import LoginButton from './login-button'

import Player from './New_Player'




const logo = "https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg"

function Hero() { 


    // const isMobile = useMediaQuery({ query: '(max-width: 1000px)' });
   
    

  const  db =  firebase.firestore()
  const { logout,isAuthenticated } = useAuth0();
  const { user } = useAuth0();
  const [token,setToken] =React.useState(Cookies.get('user_token_withEmail') ) 
  if(user) {
    const { name, picture, email } = user;
  }
 
 useEffect(() => {
   
  // if(user) {
  //   if(Cookies.get('user_token_withEmail')) {
  //     console.log('cookie email',Cookies.get('user_toke_withEmail'))
  //    var  js = Cookies.getJSON('user_toke_withEmail')
  //     if (js.email == user.email){
  //       console.log('email ver')
  //     }
  //   }else {
  //     Cookies.set('user_token_withEmail', {email : user.email, cookie_id : uuidv4()})
  //   }
  // }
  
  // const request = require("request")
  // fetch('https://firebasestorage.googleapis.com/v0/b/the-legacy-b3f2f.appspot.com/o/email_list.txt?alt=media&token=d0da7a63-0bb2-4a2e-af4a-f36f2c05f87f',{
  //   method:'get',
  // }).then(res => {
  //   console.log(res)
  // })
  // request.get(
  //    {
  //      url: 'https://firebasestorage.googleapis.com/v0/b/the-legacy-b3f2f.appspot.com/o/email_list.txt?alt=media&token=1777cacf-1989-4080-bfc8-797613e30d93',
       
  //    },
  //    (err, response, body) => {
  //      console.log(body)
  //      const whitelist = body.split(',');
  //      var exists = false
  //      const userHasAccess = whitelist.some(function (e) {
      
  //        console.log(String(e).localeCompare("123@gmail.com"))
  //        if(e.localeCompare('123@gma il.com') == 0){
  //          exists = true
  //        }
         
  //      });
 
  //      if (exists) {
  //        /* return callback(new UnauthorizedError('Access denied.')); */
  //        console.log('has access')

  //      }
  //      else
  //      console.log('has no access')
  //    }
  //  );

var new_token = null
  if(user) {
    if(token == null) {
      console.log("token doesn't exists",token)
      new_token = uuidv4()
      setToken( {email : user.email, cookie_id : new_token})
      Cookies.set('user_token_withEmail', {email : user.email, cookie_id : new_token})
      
      
        db.collection('Users').doc(user.email).set({
          email:user.email,
          cookie_id: new_token
        }).then(() => {console.log('written user')}).catch((e) => {console.error(e)})
        console.log("email",user.email)
      
    }
    var user_json = Cookies.getJSON('user_token_withEmail')
     if (user_json.email == user.email) {
      
      console.log("token exists",token)
      db.collection("Users").doc(user.email)
      .onSnapshot((doc) => {
          var cookie_id_db = doc.data().cookie_id
          console.log("Current data: ", doc.data().cookie_id);
          if(cookie_id_db !== user_json.cookie_id) {
            console.log('not equal')
            Cookies.remove('user_token_withEmail')
            logout({
              returnTo: window.location.origin,
            })
          }else{
            console.log('equal')
          }
      });
    }else {
      Cookies.remove('user_token_withEmail')
      new_token = uuidv4()
      setToken( {email : user.email, cookie_id : new_token})
      Cookies.set('user_token_withEmail', {email : user.email, cookie_id : new_token})
      
      
        db.collection('Users').doc(user.email).set({
          email:user.email,
          cookie_id: new_token
        }).then(() => {console.log('written user')}).catch((e) => {console.error(e)})
        console.log("email",user.email)
    }
  }
  
  // var token = Cookies.get('user_token')
  // if(token == null) {
  //   console.log("token doesn't exists",token)
  //   token = uuidv4()
  //   Cookies.set('user_token',token)

  //   if(user){
  //     db.collection('Users').doc(user.email).set({
  //       email:user.email,
  //       cookie_id: token
  //     }).then(() => {console.log('written user')}).catch((e) => {console.error(e)})
  //     console.log("email",user.email)
  //   }
  // }else {
  //   console.log("token exists",token)
  //   // var cookie_id_db = null
  //   if(user) {
  //     db.collection("Users").doc(user.email)
  //   .onSnapshot((doc) => {
  //       var cookie_id_db = doc.data().cookie_id
  //       console.log("Current data: ", doc.data().cookie_id);
  //       if(cookie_id_db !== token) {
  //         console.log('not equal')
  //         Cookies.remove('user_token')
  //         logout({
  //           returnTo: window.location.origin,
  //         })
  //       }else{
  //         console.log('equal')
  //       }
  //   });

      // var userRef = db.collection('Users').doc(user.email)
      // userRef.get().then((doc) => {
      //   if(doc.exists) {
      //     console.log(doc.data.email)
      //   }
      // })
    // Cookies.remove('user_token')
  //   }
    
  // }
 
  
 
 })
  return(

    isMobile ? 

  isAuthenticated ? <Player/> :  <Box>
    
  <Center  h='80vh'>
    <div>
      <Center>
        <Image src={logo}
        boxShadow="2xl" p="6" rounded="md" bg="white"
      boxSize="250px"
      objectFit="cover"
    
    />
      </Center>
    
    <Center  width="100%"><Heading textAlign="center" color="grey" paddingBlock='30px' size="lg" fontSize="4.0vh">Legacy Subscriber Login</Heading>
  
    </Center>
    <Center><LoginButton/></Center>
   
    </div>
  </Center>
</Box>

:    <Center  width="100%"><Heading textAlign="center" color="grey" paddingBlock='30px' size="lg" fontSize="4.0vh">Access this site on mobile</Heading>
</Center>
 

);
}

export default Hero;
