
// import './App.css';
import ReactJkMusicPlayer from 'react-jinke-music-player'
import './player.css'





import React from 'react';

import {Menu,Text, MenuItem, MenuDivider, Circle} from "@chakra-ui/react"



function Player() {



  const[audioInstance, setaudioInstance] = React.useState(null);

  function handleClick(id) {
    if(audioInstance) {
      audioInstance.updatePlayIndex(id)
    }
  }
  const audioList = [{
    id:0,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/Ninu%20Aradhinthumu%20Yessiah.mp3',
    name: 'Ninu Aradhinthumu Yessiah',
    singer: 'Solomon Gollapalle'
  }, {
    id:1,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/Hallelujah%20Mix%202%206%206%2021.mp3',
    name: 'Athyunatha Simhasanamupai',
    singer: 'Solomon Gollapalle'
  },
  {
    id:2,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/HALLELUAH%20STUTHI%20MAHIMA.mp3',
    name: 'Hallelujah Stuthi Mahima',
    singer: 'Solomon Gollapalle'
  },
  {
    id:3,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/SONG%204%20PRAISE%20BREAK.mp3',
    name: 'Praise Break',
    singer: 'Solomon Gollapalle'
  },
  {
    id:4,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/SONG%205%20RANDI%20YEHOVANU%20GURCHI%20USTSAHA%20GANAMU.mp3',
    name: 'Randi Yehovanu Gurchi Ustsaha Ganamu',
    singer: 'Solomon Gollapalle'
  },
  {
    id:5,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/SONG%205%20DEVA%20NEE%20KORAKU%20NA%20PRANAMU.mp3',
    name: 'Deva Nee Koaraku Na Pranamu',
    singer: 'Solomon Gollapalle'
  },
  {
    id:5,
    cover: 'https://res.cloudinary.com/lucidb/image/upload/v1624188868/49c100a9-c3d7-48df-8240-647cc7950c39_swtuxv.jpg',
    musicSrc: 'https://keysforlifeproductions.b-cdn.net/PRAISE%20MEDLEY.mp3',
    name: 'Praise Medley',
    singer: 'Solomon Gollapalle'
  }
   
]
  return (
    <div className="App">




            {/* <Container style={{position:'absolute', top:'20vh',left:'30%'}}  boxShadow="base" rounded="md" > */}
            <div style={{paddingTop:'20px'}}>
                <Menu>
        

        {audioList.map((element,id) => <> 
        <Circle >
        <MenuItem  onClick={() => handleClick(element.id)}>
        <img style={{width:'20px',margin:'10px'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAABmJLR0QA/wD/AP+gvaeTAAADvklEQVR4nO2cTW8NURjHf71XvLRFLSu6YKH2hL2F1wiaWAo2Vj6AjRWJNBKKL0HVS1dY4AMQKwsVpUqqhCYtSSOhtZi5CZfbc6b3nHnm3vn/kpM2OTPnOef5z5wz8zznDgghhBBCCCGEEEII0Vr0ASPAHLCYc5kD7gL90UdZUPqAr+Tv+Poyk/aldIxg7/xaGc7a+Y7Mwy0ec8Ba606kzAHrs5zQDgIsWnegjkw+rcTqhfBDAhgjAYxZYd2BHAi9zgVdc3QHGCMBjJEAxkgAYySAMRLAGAlgTBneA4oWK/oL3QHGSABjJIAxEiAss1lPkABheWjdAQus88C18gXYFHmshcTa8bPATZbp/DK8BxQ67601wBgJYIwEMEYCGCMBjJEAxkgAYySAMRLAGAlgjAQwxioWVAF2ALvTv1tJglldwE/gM/AJmACeAI+A1yY9bTN6gQvAJNmjjmPAGaC7rk3XeQLYAFwF5mk+/DsDnANWpm1LAAcDJNNJ6Dj8C2CXx3GlpUpy1cdMhvz0OKaUrAbukE9GSgLUUQVuYe/40goQe9qRAEswQDbnvAWGgD0k31roSks/sJdEzImMbZZWgPXAFH5OmQROk0xXLirAMWDcs+3SCnANP4fcANYso/1Okm8xSID/0IvfS9ZFmtsm0gEMetgpnQAX8LvyQ+zR6SDbndD2VIB3uBfb5Uw7jej0sFkaAXbidsLxCHZPetgthQBncV/9MfIOVfzugkITwjE7HPX3gIUAdur5BYxGaDdXQgjg+ljdgwA2GnE/Ytu5EEKAjY768QA2GvEmYtstww+WnoPrM1gh6XbYLsUaYImr/z9y6UUThBDgu6O+N4CNRrimv28RbQchhABTjvotAWw0YrOj/mNE20EIIcCYo/5AABuNOOiofxnRdhBCCPDMUX84kJ16qsAhxzGuvrUFPqGIExHsnvKw63pJbAt8gnHvSAJooejCvblrgtZ/yvPGJxw9TLhwtM8Hu88HsNUy+CZkBmk+IXPJw848cR9/C4nvbohhljcddeH/qfqhJsbRsqzDPyn/nuxJ+TeebU8DPYHG1HIMkISefRxVW5yvAfuAbSRXeXf6/37gOv6Zr8XU9pHooyw4Q/g7LHS5nMP4Co/V1sRR/Ka0UrCKfDfn3k5tij+oAlfItiZkLQsk046u/CU4SvJkEtr502nbwoMewv1EaZ5koS/to2Yz9JKECLI8WtbKRHpuW73hWn3OqwJs59+fqdbyx9+BD8Ar4CnwGHhOnO0tQgghhBBCCJErvwEQFJnsS3XUBQAAAABJRU5ErkJggg=="/>
        <Text>{element.name}</Text>
       
        </MenuItem></Circle>
        <MenuDivider/>
        </>
        )
        }
        
   
    </Menu>
            </div>
            
      {/* <div className="container">
      {
                audioList.map((element,id) => 
                
                <ul className="m_ul">
                    <li className='m_li' key={id}>
                      
                      <div className="listItem">
                      {id==1 && <div>
                           
                        </div>}
                      <div onClick={() => handleClick(element.id)} id="listSong" style={{width:'90%',padding:'10px',cursor:'pointer'}} >
                            <span >{element.name}</span>
                      </div>
                    
                       
                            <div className="clicks">
                        <i className="fas fa-compact-disc"></i> <span></span>
                        
                        </div>
                        </div>
                     
                        
                    </li>
                    < hr style={{width:'80%',opacity:'0.2',border:'1px solid'}} />
                </ul> 
                  
                      )
              }
      </div>  */}
      <ReactJkMusicPlayer 
      getAudioInstance = {(insatnce) => {setaudioInstance(insatnce)}}
      audioLists={audioList}
       toggleMode={false} 
       theme={'light'} 
       showDownload={false} 
       showMiniProcessBar={true} 
       mode={'full'} 
       showMiniModeCover={false} 
       remove={false} 
       responsive={true}
       showDestroy={false}
       showReload={false}
       showThemeSwitch={false}
       showPlayMode={false}
       autoHiddenCover={false}
       showMediaSession ={true}
       autoPlay={false}
       />
    </div>
  );
}

export default Player;



