import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import AuthNav from "./auth-nav";

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  IconButton,Flex,Container
} from "@chakra-ui/react"

import {ChevronDownIcon} from '@chakra-ui/icons'
const NavBar = () => {
  const { user,isAuthenticated } = useAuth0();
 
  return (
    <div >
     
        <div  >
          <div style={{zIndex:'2000',position:'absolute',width:'50px',height:'10px',left:'0'}}>
        <Flex align='flex-end' float='right'>
        <Menu>
  <MenuButton 
    as={IconButton}
    aria-label="Options"
    icon={<ChevronDownIcon />}
    variant="outline"
  /> 
  <MenuList>
    {isAuthenticated ? <MenuItem >{user.name}</MenuItem>:undefined}
    <MenuDivider/>
    <MenuItem>
<AuthNav />
    </MenuItem>
  </MenuList>
  </Menu>
  </Flex>
  </div>
        </div>
     
    </div>
  );
};

export default NavBar;
