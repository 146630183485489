import React, { Fragment } from "react";

import { Hero, HomeContent } from "../components";


const Home = () => (
  

    <Fragment>
    <Hero />
 
   
  </Fragment>
  
  
);

export default Home;
